jQuery(document).ready(function($) {

  $(document).scroll(function(){
    scroll = $(this).scrollTop();
    $("#mainMenuNavigation").css("top", Math.max(0, 40 - scroll) + "px");
  });

  $(".navigation .block-menu, .mobileMenuOpener").click(function(){
    $(".navigation .block-menu .menu").toggleClass('expanded');
  });

  $("select").selectric();

  $(".showPastEvents").click(function(){
    if( $(this).hasClass("show") ) {
      $(this).removeClass("show");
      $(this).children('span.text').text('Genomförda aktiviteter');
      $(".oldEvent").removeClass("shown");
      $(".oldEvent").addClass("hidden");
    } else {
      $(this).addClass("show");
      $(this).children('span.text').text('Genomförda aktiviteter');
      $(".oldEvent").addClass("shown");
      $(".oldEvent").removeClass("hidden");
    }

    //$(".oldEvent").toggleClass("shown");
    //$(".oldEvent").toggle();
    $("#medlemsaktiviteter").change();
  });

  $(".showMoreUpcomingEvents").click(function(){
    if( $(this).hasClass("show") ) {
      $(this).removeClass("show");
      $(this).children('span.text').text('Se fler kommande aktiviteter');
      $(".hiddenEvent").addClass("hidden");
      $(".hiddenEvent").removeClass("shown");
    } else {
      $(this).addClass("show");
      $(this).children('span.text').text('Se färre kommande aktiviteter');
      $(".hiddenEvent").addClass("shown");
      $(".hiddenEvent").removeClass("hidden");
    }

   // $(".hiddenEvent").toggleClass("shown");
    //$(".hiddenEvent").toggle();
    $("#medlemsaktiviteter").change();
  });
  if($("input[type=checkbox]").length > 0) {
    // Sprites would've been preferred
    preloadCheckbox = new Image();
    preloadCheckbox.src = '/sites/all/themes/forsakringsforeningen/images/selectbox-inactive.png';
    preloadCheckbox.src = '/sites/all/themes/forsakringsforeningen/images/selectbox-active.png';
    $(".filter input").change(function () {
      $('.resultsGrouping:not(.oldEvent), .resultsGrouping.oldEvent.shown').show();
      $(".filter input").each(function () {
        // console.log($(this).val(), $(this).is(':checked'));
        if ($(this).is(':checked')) {
          $("." + $(this).val()).css('display', 'inline-block');
          $("." + $(this).val()).addClass('activity');
        } else {
          $("." + $(this).val()).hide();
          $("." + $(this).val()).removeClass('activity');
        }
      });
      $(".resultsGrouping").find(".activity:visible:odd").removeClass("even").addClass("odd");
      $(".resultsGrouping").find(".activity:visible:even").removeClass("odd").addClass("even");
      $(".ajax_search_results .results").find(".activity:visible:odd").removeClass("even").addClass("odd");
      $(".ajax_search_results .results").find(".activity:visible:even").removeClass("odd").addClass("even");
      $('.resultsGrouping').each(function(){
        if($(this).children('.activity:visible').length == 0){
          $(this).hide();
     //     $(this).removeClass("shown");
        } else {
          $(this).show();
        }
      });
    });
  }

  $("#block-views-stipendium-block-2 .views-row:nth-child(1n+4)").css('display', 'none');
  $(".toggleStipendium").click(function(e){
    $.cookie("showAllStipendiums", !$(this).hasClass("open"), {expires: 1});
    if($(this).hasClass("open")){
      $(this).text('VISA ALLA STIPENDIER');
      $("#block-views-stipendium-block-2 .views-row").hide();
      $("#block-views-stipendium-block-2 .views-row:lt(3)").show();
      $(this).removeClass("open");
      $('html, body').scrollTop($(".toggleStipendium").offset().top);
    } else {
      $(this).addClass("open");
      $(this).text('DÖLJ STIPENDIER');
      $("#block-views-stipendium-block-2 .views-row").show();
    }
    e.preventDefault();
  });

  // Auto-open stipendiums if true
  if($.cookie("showAllStipendiums")=="true"){
    $(".toggleStipendium").click();
  }

  // FIX DRUPAL WYSIWYG FIXED WIDTHS...
  $("table").each(function(){
    if(typeof $(this).attr("style") != 'undefined' && $(this).attr("style").indexOf("width") > -1){
      $(this).css("max-width", $(this).css("width")).css("width", "100%"); //.css("display", "block");
    }
  });

  $(".form-type-checkbox label").each(function(){
    $(this).append('<span class="checkbox-replacement"></span>');
  });

  $('.button.register-form-add-company').on('click', function(e){
    e.preventDefault();
    $(this).parent('.form-type-item').hide();
    $('input[name="other_employer_checked"').prop( "checked", true ).change();
    $('input[name="no_employer"').prop( "checked", false ).change();
    $('input[name="employer"').val("").change();
  });
  $('input[name="employer"').on('change', function(){
    if( $(this).val().length > 0 ){
      $('input[name="no_employer"').prop( "checked", false ).change();
    }
  });

  $('input[name="no_employer"').on('change', function(){
    if($(this).is(':checked')){
      $('input[name="employer"').val("").change();
    }
  });

  var tim1;
  $('.kalendarium .ajax_search input[name="search"]').on('input', function(){
    clearTimeout(tim1);
    tim1 = setTimeout(function(){
      var input = $('.kalendarium .ajax_search input[name="search"]');
      if( input.val().length > 2 ){
        $('.kalendarium .lds-dual-ring').show();
        $('.kalendarium .ajax_search_results .results').hide();
        $('.kalendarium .resultsGrouping:not(.hidden), .kalendarium .showMoreUpcomingEvents').addClass('search-hide');
        $.ajax({url: "/kalendarium/search-ajax/"+input.val()
          ,success: function(result){
            $('.kalendarium .ajax_search_results .results').html(result);
            $('.kalendarium .lds-dual-ring').hide();
            $('.kalendarium .ajax_search_results .results').show();
            $(".filter input").change();
        }});
      }
    },500);
  });

  $('.kalendarium .ajax_search .clear-ajax-search').on('click', function(){
    $('.kalendarium .ajax_search_results .results').html('');
    $('.kalendarium .ajax_search input[name="search"]').val('');
    $('.kalendarium .lds-dual-ring').hide();
    $('.kalendarium .ajax_search_results .results').hide();
    $('.kalendarium .search-hide').removeClass('search-hide');
    $(".filter input").change();
  });

  if( $('#block-sff-alert .alert-container').length > 0 ){
    setTimeout(function(){
      $('#block-sff-alert .alert-container').addClass('active');
    }, 500);
  }

  $('#block-sff-alert .alert-container .container .close').on('click', function(){
    document.cookie = "alert_accepted=1; expires=0; path=/";
    $('#block-sff-alert .alert-container').removeClass('active');
  });

});